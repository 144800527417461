import React, { Component } from 'react';
import { Container, Row, Col,Jumbotron, Button  } from 'reactstrap';
import  { Link } from 'react-router-dom';
import BannerStyle from './bannerStyle.scss';

import heroImg from './../../assets/img/dashboard2.png';
import mobileHeroImg from './../../assets/img/mobile-hero-img.png';
class BannerComponent extends Component{

    render() {
        return (
            <div className="hero-img">
                <Jumbotron  fluid className=" bgImage">
                <Container>
                    <Row className="justify-content-center">
                    <Col className="text-center">
                            <h1 className="text-uppercase text-dark">The Next Generation of<br/><span>Touchless Parking</span></h1>
                            <p className="  text-dark font-weight-bold ">The exclusive digital platform for the parking industry.</p>
                            <div className="text-center  hero-baanner">
                                {/* <p className="text-center hero-baanner-img"><img alt="	The Next Generation of your Customers" className="" src={heroImg} /></p> */}
                                <p className="text-center hero-baanner-img">
{/* <iframe width="600" height="350" src="https://www.youtube.com/embed/5g1Jroe4FDs" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
<iframe width="620" height="350" src="https://www.youtube.com/embed/5g1Jroe4FDs?rel=0&enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen></iframe></p>
                                <p className="text-center hero-baanner-img-mob">
<iframe id="ytplayer" type="text/html" width="95%" height="200" src="https://www.youtube.com/embed/5g1Jroe4FDs?rel=0&enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen></iframe>

</p>
<p className="text-center pt-3 mb-0"><a href="mailto:info@parkengage.com" className="text-white min-width  btn btn-primary">Contact Us for a Free Trial</a></p>
                            </div>
                        </Col>
                    </Row>
                    </Container>
                    
                </Jumbotron>
                
                </div>

            )
    }

}

export default BannerComponent
