import * as actionTypes from './../ActionTypes';
import  Axios  from './../../config/axios';

export const ForgetPasswordRequest = ()=> {
    return {
        type: actionTypes.FORGET_PASSWORD_REQUEST
    }
}

export const ForgetPasswordSuccess = (res)=> {
    return {
        type : actionTypes.FORGET_PASSWORD_SUCCESS,
        data : res
    }
}
export const ForgetPasswordFail = (error)=> {
    return {
        type : actionTypes.FORGET_PASSWORD_FAIL,
        data: error.response
        
    }
}

export const ForgetPasswordRequestService = (data)=> {
    return dispatch => {
       dispatch( ForgetPasswordRequest() ) ;
       return Axios.post('/user/reset-password/email', data)
       .then ( res => {
           return dispatch ( ForgetPasswordSuccess( res ) )
       } ).catch( error => {
           return dispatch ( ForgetPasswordFail (error) )
       } )
    }
}

export default ForgetPasswordRequestService;