import React, { Component } from 'react';
import Aux from '../pages/Wrapper/Auxiliary';
import { connect } from "react-redux";
import * as actionCreators from "../Redux/Actions/index";
import NotificationSystem from "react-notification-system";
import BreadCrumb from '../pages/BreadCrumb/BreadCrumb';
import SimpleReactValidator from 'simple-react-validator';

class ForgetPassword extends Component {
    constructor(props){
        super(props);
        this.notificationSystem = React.createRef();
        this.validator = new SimpleReactValidator();

        this.state = {
            formData: {
                email: {
                    name: 'email',
                    value: '',
                    placeholder: 'Enter registered Email',
                    validationMsg: '',
                    type: 'email',
                    id: 'email',
                    class: 'form-control',
                },
            },
            formIsValid: false,
            loading: false,
        }        
    }

    componentWillMount(){
       
    }

    inputHandler = (event)=> {
        const { name,value } = event.target;
        let prevStateData = this.state.formData
        prevStateData[name].value  = value;
         this.setState( { formData: prevStateData} )
     }

    submitHandler = (event)=> {
        event.preventDefault();
        const notification = this.notificationSystem.current;
        const { formData } = this.state
        if (this.validator.allValid()) {
         let emailId = { email: formData.email.value }
            this.setState({ loading: true });
             this.props.GeneratePasswordRequestHandler(emailId).then(() => {
                if(this.props.result.status ==201){
                      notification.addNotification({
                          title: 'Email is sent successfully!',
                          message: 'Please check your email to reset password',
                          level: 'success'
                      });
                }
                else{
                    let error = this.props.result.data.errors;
                     notification.addNotification({
                         title: '"'+error.message+'"',
                         message: '"'+error.detail.email+'"',
                         level: 'error'
                     });
                     //setTimeout(()=>{ this.props.history.push('/'); }, 2000);
                }
                formData['email'].value  = '';
                formData['email'].formIsValid = ''
                this.setState({ loading:false, formData : formData, formIsValid: false})
             })
            
            } else {
                this.validator.showMessages();
                this.forceUpdate();
                //notification.addNotification({
               //   title: 'Validation Error!',  message: 'Fill Required field!', level: 'error'
               // });
            }


        
    }

    render() {
        let Field = this.state.formData.email;
        const { formIsValid } = this.state
        return(
            <Aux>
            <NotificationSystem ref={this.notificationSystem} />
            <BreadCrumb
                headerTitle={`Forgot Password`} headerName={"Forgot Password"}  headerContent={ "" } />
                <section id="content-section">
                <div className="container">
                <div className="login-popup">
                <div className="container">
                    <div className="mt-4 mb-5 p-0">
                    <div className="row justify-content-center">
                    <div className="col-sm-7">
                        <div className="login-rt border  p-3 p-sm-5">

                        <form name="forgetPasswordForm" onSubmit={this.submitHandler}>
                            <div className="alert alert-primary mb-4" role="alert">
                            Please enter your registered Email
                            </div>
                            <div className="form-group mb-4">
                                <input type={Field.type} name={Field.name} onChange={this.inputHandler} onBlur={this.inputHandler} value={Field.value}  className={Field.class} placeholder={Field.placeholder} />
                                {this.validator.message('Email' , Field.value, 'required|email')}
                            </div>
                                
                            {!this.state.loading && 
                            <button type="submit" className="btn btn-primary btn-outline-primary">Generate Password</button>
                            }
                            { this.state.loading && 
                            <button type="button" disabled className="btn btn-primary btn-outline-primary">Generate Password</button>
                            }
                        </form>
                        
                         </div>
                    </div>
                    </div>
                    </div>
                </div>
                </div>
                    </div>
                </section>

            </Aux>
        )
    }

}


const mapStateToProps = state => {
    return {
        isFetching: state.forgetPasswordReducer.isFetching,
        result: state.forgetPasswordReducer.result
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        GeneratePasswordRequestHandler: (data) => dispatch(actionCreators.ForgetPasswordRequestService(data)),
    }
}

 export default connect( mapStateToProps, mapDispatchToProps )(ForgetPassword)
