import React, { Component } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import UserInfoMenu from './userInfo'
import $ from 'jquery';

import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button
  
} from 'react-bootstrap';


class MainNavbar extends Component {
  isAuthenticated = false;
  username = '';
  constructor(props) {
    super(props);
  }
  componentDidUpdate( prevProps ){
     
    }

    updateFormStatus = ()=> {
      $('footer .srv-validation-message').hide()
  }
  rediretToServiceDetails = (service, Id)=> {
    this.props.history.replace({ pathname: service})
    window.location.reload();
}

  render() {
    return (  
 
      <Navbar.Collapse  className={this.props.headerCls+" bg-transparent p-3 p-md-0 collapse navbar-collapse"} id="mainNav">
      {/* <div navbar className={this.props.headerCls+" bg-transparent p-3 p-md-0 collapse navbar-collapse"} id="mainNav"> */}

      {/* <Collapse  navbar  id="mainNav"  className={this.props.headerCls+" bg-transparent p-3 p-md-0 collapse navbar-collapse"}> */}
    
        
      <Nav className="ml-auto">
     
     
          {/* <li className="nav-item">
            <NavLink className="nav-link" to={"/"} onClick={this.updateFormStatus} >Home</NavLink>
          </li> */}
          <li className="nav-item">
            <NavLink className="nav-link" to={"/about-us"} onClick={this.updateFormStatus} >Who We Are</NavLink>
          </li>
          <li className="nav-item dropdown custom-dropdown">
            <NavLink className="nav-link dropdown-toggle" to="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">What We Do</NavLink>
            <div class=" dropdown-menu "  aria-labelledby="navbarDropdownMenuLink">
              <div className='row no-gutters'>


              <NavLink className="dropdown-item col-sm-6 p-2" to={"/services/touchless-parking-system"} onClick={()=>this.rediretToServiceDetails('/services/touchless-parking-system',5)} >Touchless Self Parking</NavLink>
              <NavLink className="dropdown-item col-sm-6 p-2" to={"/services/valet-parking-system"} onClick={()=>this.rediretToServiceDetails('/services/valet-parking-system',6)} >Touchless Valet Parking</NavLink>
              <NavLink className="dropdown-item col-sm-6 p-2" to={"/services/permit-parking-system"} onClick={()=>this.rediretToServiceDetails('/services/permit-parking-system',3)} >Touchless Permit Parking</NavLink>
              <NavLink className="dropdown-item col-sm-6 p-2" to={"/services/monthly-parking-system"} onClick={()=>this.rediretToServiceDetails('/services/monthly-parking-system',3)} >Touchless Monthly Parking</NavLink>
              <NavLink className="dropdown-item col-sm-6 p-2" to={"/services/online-reservations"} onClick={()=>this.rediretToServiceDetails('/services/online-reservations',4)} >Online Parking Reservation</NavLink>
              <NavLink className="dropdown-item col-sm-6 p-2" to={"/services/parking-inventory-management-system"} onClick={()=>this.rediretToServiceDetails('/services/parking-inventory-management-system',1)} >Aggregator and Channel Management</NavLink>
              <NavLink className="dropdown-item col-sm-6 p-2" to={"/services/promotion"} onClick={()=>this.rediretToServiceDetails('/services/promotion',2)} >Promotions and Loyalty</NavLink>
              <NavLink className="dropdown-item col-sm-6 p-2" to={"/services/parking-enforcement-system"} onClick={()=>this.rediretToServiceDetails('/services/parking-enforcement-system',8)} >ALPR Based Parking Enforcement</NavLink>
              <NavLink className="dropdown-item col-sm-6 p-2 border-0" to={"/services/analytics-system"} onClick={()=>this.rediretToServiceDetails('/services/analytics-system',10)} >Reporting and Analytics Dashboards</NavLink>
              <NavLink className="dropdown-item col-sm-6 p-2 border-0" to={"/services/white-label-apps"} onClick={()=>this.rediretToServiceDetails('/services/white-label-apps',11)} >White Label Apps</NavLink>
            </div>
            </div>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/news" onClick={this.updateFormStatus} >News</NavLink>
            {/* <a className="nav-link" href="https://test.parkengage.com/news" target="__blank" >News</a> */}
            {/* <NavLink className="nav-link" target="__blank" to="https://test.parkengage.com/news" onClick={this.updateFormStatus} >News</NavLink> */}
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/request-a-demo" onClick={this.updateFormStatus} >Request a Demo</NavLink>
          </li>       
          <li className="nav-item">
            <NavLink className="nav-link" to="/career" onClick={this.updateFormStatus} >Career</NavLink>
          </li>        
          {/* <li className="nav-item ">
            <NavLink className="nav-link" to="/contact-us" onClick={this.updateFormStatus} >Contact Us</NavLink>
          </li> */}
         
        
          {this.props.isAuthenticated}
            { this.props.isAuthenticated &&  
             
              <UserInfoMenu username={this.props.username}></UserInfoMenu>
            
            }
            {
              !this.props.isAuthenticated &&  
              <li className="nav-item no-dot">
              <Link className="btn btn-primary btn-sm text-light mt-1"  role="button" data-toggle="modal" data-target="#myModal">Login</Link></li>
            }
         
         </Nav>
        {/* </Collapse> */}
        </Navbar.Collapse>
     
      
    )
  }
}

export default withRouter(MainNavbar);