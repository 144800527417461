import React, { Component } from 'react';
import Aux from '../pages/Wrapper/Auxiliary';
import { connect } from "react-redux";
import * as actionCreators from "../Redux/Actions/index";
import NotificationSystem from "react-notification-system";
import SimpleReactValidator from 'simple-react-validator';
import loginStyle from './login.scss';
import BreadCrumb from '../pages/BreadCrumb/BreadCrumb';
import $ from 'jquery';

class ResetPassword extends Component {
    constructor(props){
        super(props);
        this.notificationSystem = React.createRef();
        this.validator = new SimpleReactValidator();
        this.state = {
            formData: {
                email: {
                    name: 'email',
                    value: '',
                    validationMsg: '',
                },
                password: {
                    name: 'password',
                    value: '',
                    validationMsg: '',
                },
                password_confirmation: {
                    name: 'password_confirmation',
                    value: '',
                    validationMsg: '',
                }
            },
            formIsValid: false,
            loading: false,
            showError : false,
            errorMsgPwd: '',
            errorMsgCPwd: '',
        }  
    }

    componentWillMount(){
    }

    inputHandler = (event)=> {
        const { name, value } = event.target;
        // let prevStateData = this.state.formData
        const { errorMsgPwd, errorMsgCPwd, formData } = this.state
        formData[name].value  = value;
        this.setState( { formData: formData, errorMsgPwd : '', errorMsgCPwd: '' } )
       
     }
    

    submitHandler = (event)=> {
        event.preventDefault();
         //let passw=  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
         let passw = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,15}$/;
        const notification = this.notificationSystem.current;
        if (this.validator.allValid()) {
        const { formIsValid , errorMsgPwd , errorMsgCPwd} = this.state
        const formDataFilled = {};
        for (let formElement in this.state.formData) {
            formDataFilled[formElement] = this.state.formData[formElement].value;
        }
        formDataFilled['token'] =  this.props.match.params.token;
        if(!( passw.test(formDataFilled.password) )){ 
            let errorMsgPwd  = 'Entered Password does not match with provided Password Hint';                 
            this.setState({errorMsgPwd: errorMsgPwd})
        } else {
            if(formDataFilled.password !== formDataFilled.password_confirmation ){ 
                let errorMsgCPwd  = 'Confirm Password should be same as password';
                this.setState({errorMsgCPwd: errorMsgCPwd})
            } else{
            this.setState({ loading: true });
             this.props.ResetPasswordRequestHandler(formDataFilled).then(() => {
                    if(this.props.result.status ==201){
                       notification.addNotification({
                           title: 'Successfully!',
                           message: 'Password has been updated Successfully',
                          level: 'success'
                      });
                      setTimeout(()=>{ this.props.history.push('/'); }, 2000);
                      $("#myModal").modal("show"); 
                }
                else if(this.props.result.status) { 
                      notification.addNotification({
                          title: '"'+this.props.result.data.errors.message+'"',
                          message: '',
                          level: 'error'
                      });
                      //setTimeout(()=>{ this.props.history.push('/'); }, 2000);
                 }else {
                    this.setState({ showError: true });
                 }
                 this.setState({loading:false})
                })                                                                               
            }
        }

    }else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        const { formIsValid, formData, errorMsgPwd, errorMsgCPwd } = this.state
        return(
            <Aux>
            <NotificationSystem ref={this.notificationSystem} />
            <BreadCrumb
                headerTitle={`Create Password`} headerName={"Reset Password"}  headerContent={ "" } />
                <section id="content-section">
                <div className="container">
                <div className="login-popup">
                <div className="container">
                    <div className="mt-4 mb-5 p-0">
                    <div className="row justify-content-center">
                    <div className="col-sm-7">
                        <div className="login-rt border px-5 py-5">
                            <div className="alert alert-primary" role="alert">
                            Password should 7 to 15 characters, at least one uppercase letter, one lowercase letter, one number and one special character.
                            </div>
                        <form name="forgetPasswordForm" onSubmit={this.submitHandler}>

                <div className="form-group mb-3">
                  <input type="test" name="email" onChange={this.inputHandler} onBlur={this.inputHandler} value={formData.email.value}  className="form-control" placeholder="Enter registered Email" />
                    {this.validator.message('Email' , formData.email.value, 'required|email')}
                </div>
                <div className="form-group mb-3">
                  <input type="password" name="password" onChange={this.inputHandler} onBlur={this.inputHandler} value={formData.password.value}  className="form-control" placeholder="Enter New Password" />
                  { errorMsgPwd  && 
                     <p className="input-errors">{errorMsgPwd}</p>
                  }
                  {!errorMsgPwd &&
                  this.validator.message('Password' , formData.password.value, 'required')}
                </div>
                <div className="form-group mb-3">
                  <input type="password" name="password_confirmation" onChange={this.inputHandler} onBlur={this.inputHandler} value={formData.password_confirmation.value}  className="form-control" placeholder="Enter Confirm Password" />
                  { errorMsgCPwd  && 
                    <p className="input-errors">{errorMsgCPwd}</p>
                  }
                 {!errorMsgCPwd &&
                  this.validator.message('Confirm Password' , formData.password_confirmation.value, 'required')}
                </div>

                {!this.state.loading && 
                    <button type="submit" className="btn btn-primary btn-outline-primary">Create Password</button>                    }
                { this.state.loading && 
                  <button type="button" disabled className="btn btn-primary btn-outline-primary">Create Password</button>
                }
                        </form>
                        
                         </div>
                    </div>
                    </div>
                    </div>
                </div>
                </div>
                    </div>
                </section>

            </Aux>
        )
    }

}


const mapStateToProps = state => {
    return {
        isFetching: state.ResetPasswordReducer.isFetching,
        result: state.ResetPasswordReducer.result
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ResetPasswordRequestHandler: (data) => dispatch(actionCreators.ResetPasswordRequestService(data)),
    }
}

 export default connect( mapStateToProps, mapDispatchToProps )(ResetPassword)
