import React, { Component } from 'react';
export default class CpoyrightMenu extends Component {
    render() {
        return (
            <div className="col-sm-12   text-center col-12">
                
                <ul className="list-inline mb-0">
                    
                    <li className="list-inline-item text-primary">
                        &copy;2022 <a>|</a> <a href="https://www.parkengage.com">www.parkengage.com</a>
                    </li>
                </ul>
            </div>
        )
    }
}