import * as actionTypes from "../ActionTypes";
import axios from "../../config/axios";

export const demoRequestStart = () => {
  return {
    type: actionTypes.DEMO_REQUEST
  };
};

export const demoRequestSuccess = (res) => {
  return {
    type: actionTypes.DEMO_REQUEST_SUCCESS,
    data: res.data
  };
};

export const demoRequestFail = (error) => {
  return {
    type: actionTypes.DEMO_REQUEST_FAIL,
    error: error
  };
};

export const demoServiceDataRequestStart = () => {
  return {
    type: actionTypes.DEMO_DATA_REQUEST
  };
};

export const demoServiceDataRequestSuccess = (res) => {
  return {
    type: actionTypes.DEMO_DATA_REQUEST_SUCCESS,
    data: res
  };
};

export const demoServiceDataRequestFail = (error) => {
  return {
    type: actionTypes.DEMO_DATA_REQUEST_FAIL,
    error: error
  };
};



export const countryRequestStart = () => {
  return {
    type: actionTypes.FETCH_COUNTRY_REQUEST
  };
};

export const countryRequestSuccess = (res) => {
  return {
    type: actionTypes.FETCH_COUNTRY_SUCCESS,
    data: res
  };
};

export const countryRequestFail = (error) => {
  return {
    type: actionTypes.FETCH_COUNTRY_FAIL,
    error: error
  };
};



export const requestForDetailsDemo = (reqData) => {
  return dispatch => {
    dispatch(demoRequestStart());    
    let FormData = {
      name: reqData.name,  mobile: reqData.contact,email:  reqData.email, company_name:  reqData.company_name, city:  reqData.city,state:  reqData.state,
      country:  reqData.country, service_id: reqData.product, form_status : 'demoForm'
    };
    // pass the FormData in request
    reqData ['form_status'] = 'demoForm';
    return axios.post('/save-request-demo', FormData)
      .then(res => {
        return dispatch(demoRequestSuccess(res.data));
      })
      .catch(err => {
        return dispatch(demoRequestFail(err));
      });
  };
}

export const requsrtFetchServicesDemo = ( )=> {
  return dispatch => {
    dispatch(demoServiceDataRequestStart());
     return axios.get('/services')
     .then( res => {
       //console.log(res);
       return dispatch ( demoServiceDataRequestSuccess( res.data ) );
     }  )
     .catch ( err => {
       return dispatch ( demoServiceDataRequestFail() );
     } )
  }

}

export const requestFetchCountry = ( )=> {
  return dispatch => {
    dispatch(countryRequestStart());
     return axios.get('/countries')
     .then( res => {
       //console.log(res, 'countries');
       return dispatch ( countryRequestSuccess( res.data ) );
     }  )
     .catch ( err => {
       return dispatch ( countryRequestFail() );
     } )
  }

}