import React, { Component } from 'react';
import {Redirect, withRouter, Link} from 'react-router-dom';
import { connect } from "react-redux";
import * as actionCreators from "./../Redux/Actions/index";
import NotificationSystem from "react-notification-system";
import loginStyle from './login.scss';
import loginCross from './assets/login-cross.svg';
import loginBg from './assets/login-bg.png';
import urlCongigration from './../config/urlCongigration';
import $ from 'jquery';
import SimpleReactValidator from 'simple-react-validator';
import {fade,makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { getWebUrl } from './helper';



const useStylesReddit = makeStyles((theme) => ({
    root: {
      border: '1px solid #e2e2e1',
      overflow: 'hidden',
      borderRadius: 4,
      backgroundColor: '#fff',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:hover': {
        backgroundColor: '#fff',
      },
      '&$focused': {
        backgroundColor: '#fff',
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        borderColor: theme.palette.primary.main,
      },
    },
    focused: {},
  }));
  function RedditTextField(props) {
    const classes = useStylesReddit();
  
    return <TextField InputProps={{ classes, disableUnderline: true }} {...props} />;
  }


class LoginComponent extends Component {
    
    adminUrl = urlCongigration()?.adminBaseUrl;
    customerUrl = urlCongigration()?.customerPortal;
    constructor(props){
        
        
        super(props);
        this.validator = new SimpleReactValidator({
            messages: {
                //email: 'Please enter valid email id',
                default: 'Please enter valid :attribute.'  // will override all messages
              },
            
        });
        this.notificationSystem = React.createRef();
        this.state = {
            formData: {
                username: {
                    name: 'username',
                    value: '',
                    placeholder: 'Enter registered Email',
                    validationMsg: '',
                    type: 'text',
                    class: 'form-control',
                    id: 'username',
                    valid: false
                },
                password: {
                    name: 'password',
                    value: '',
                    placeholder: 'Enter password',
                    validationMsg: '',
                    type: 'password',
                    id: 'pwd',
                    class: 'form-control',
                    valid: false
                }
            },
            formIsValid: false,
            loading: false,
            showPopup: false
        }
    }
    

    inputHandler = (event)=> {
        let mailformat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        let passw=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,15}$/;
    //    let passw = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,15}$/; // updaated regix
        let errorMsg = '';
        let isValid = false;
       const { name, value } = event.target;
    //    if( (name =='password' && value.length > 0 ) && !( passw.test(value) )){ 
        // if( (name =='password' && value.length > 0 )){ 
        //     errorMsg = 'Password should 8 to 15 characters, at least one uppercase letter, one lowercase letter, one number and one special character.'; isValid = false; 
        // }
        let prevStateData = this.state.formData
        let data = value
        data = data.trim()
          prevStateData[name].value  = data;
          prevStateData[name].validationMsg  = errorMsg;
          prevStateData[name].valid  = isValid;
         this.setState( { formData: prevStateData } )
            let isFormValid = false;
         for( let fData in this.state.formData ){
             if(this.state.formData[fData].valid){ isFormValid = true  } else { isFormValid = false }
         }
         this.setState({ formIsValid: isFormValid})
     }
    

    submitHandler = (event)=> {
        $('.srv-validation-message').show();
        event.preventDefault();
        const notification = this.notificationSystem.current;
        const formData1 = {};
        for (let formElement in this.state.formData) {
            formData1[formElement] = this.state.formData[formElement].value;
        }
        if (this.validator.allValid()) {
            this.setState({ loading: true });
            // let passw=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,15}$/;
            // if(passw.test(formData1.password)){
                this.props.requestForLoginHandler(formData1).then(() => {
                for (let field in this.state.formData) {
                    this.state.formData[field].value = '';
               }
               this.setState({ loading: false, formIsValid:false });
                if( this.props.result.status == 201 ){
                    let res = this.props.result.data
                    localStorage.setItem('access_token', res.access_token )
                    localStorage.setItem('token_type', res.token_type )
                    localStorage.setItem('expires_in', res.expires_in )
                    localStorage.setItem('refresh_token', res.refresh_token )
                    localStorage.setItem('username', res.user.name )
                    localStorage.setItem('session_start_time', Date() )
                    localStorage.setItem('user_id', res.user.id )
                    localStorage.setItem('user_type', res.user.user_type )

                    notification.addNotification({
                        title: 'Success!',
                        message: 'Login Successfully',
                        level: 'success'
                    });
                    
                    window.location.href = getWebUrl(res.user.user_type,res.access_token,res.refresh_token,this.customerUrl,this.adminUrl)
                    ;
                    $("#myModal").modal("hide"); 
                    //window.location.reload();
                    setTimeout(()=>{ this.props.history.push('/'); }, 500);
              }
              else{
                   notification.addNotification({
                       title: '"'+this.props.result.data.errors.message+'"',
                       message: 'Please enter valid information.',
                       level: 'error'
                   });
              }
              this.setState({ loading: false });
                });
            // }else{
            //     this.setState({ loading: false });
            //     console.log(formData1.password, formData1)
            // }
        }else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    sendToForgetPwd = ()=> {
        $("#myModal").modal("hide"); 
        $('.srv-validation-message').hide();
        this.props.history.push('/forgot-password');
    }


    
    render() {   
        $(document).ready(function() { 
            $("#myModal").on('hide.bs.modal', function() { 
                $('.srv-validation-message').hide();
            }); 
        }); 
        
        let Field = this.state.formData;
        let formIsValid = this.state.formIsValid;
        let style={position: 'absolute',
              right: '0px', 
              zIndex: 9
            }

        return(
            <>
            <NotificationSystem ref={this.notificationSystem} />
                    <div className="login-popup">
                    <div className="container">
                    <div className="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                        
                        
                        <div className="modal-body p-0">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" className="close py-2 px-3"  style={this.style}>
                        <img alt="cancel login popup" src={loginCross} />
                        </button>
                        <div className="row no-gutters">
                        <div className="col-lg-6 d-none d-lg-block col">
                        <div className="left-login-bg">
                        <img alt="login popup background" src={loginBg} />
                        </div>
                        </div>
                        <div className="col-lg-6 col">
                            <div className=" p-4 p-sm-5 ">
                            <div className="text-center">
                            <h1>Hi !</h1>
                            <h4 style={{fontSize:'40px', marginBottom:'30px'}}>Welcome back</h4>
                            <p>Login to your account to access your <br></br>subscribed services</p>
                            </div>
                            <form name="LoginForm" onSubmit={this.submitHandler}>
                            
                            <div className="form-group">
                                <input autocomplete="on" type={Field.username.type} name={Field.username.name}  onChange={this.inputHandler} onBlur={this.inputHandler} value={Field.username.value} className={Field.username.class} placeholder={Field.username.placeholder} id={Field.username.id} />
                                {this.validator.message('Email id', Field.username.value, 'required|email')}
                            </div>
                            <div className="form-group">
                                <input autocomplete="on" type={Field.password.type} name={Field.password.name} onChange={this.inputHandler} onBlur={this.inputHandler} value={Field.password.value}  className={Field.password.class} placeholder={Field.password.placeholder} id={Field.password.id} />
                                
                                { Field.password.validationMsg && 
                                    <small className="input-errors input-errors form-text text-muted ">{Field.password.validationMsg}</small>
                                }
                                
                                {this.validator.message('Password', Field.password.value, 'required')}
                            </div>
                            {!this.state.loading && 
                                <button type="submit" className="btn btn-primary btn-outline-primary">Login</button>
                            }
                            { this.state.loading && 
                            <button type="button" disabled className="btn btn-primary btn-outline-primary">Login</button>
                            }
                            <Link to="#" onClick={this.sendToForgetPwd} className="text-primary float-right mt-2">Forgot Password ?</Link>
                        </form>
                           </div>
                        </div>
                        </div>
                        </div>
                        
                        </div>
                    </div>
                    </div>
                    </div>
                    </div>
            </>
        )
    }

}


const mapStateToProps = state => {
    return {
        result:  state.loginRequest &&  state.loginRequest.result
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestForLoginHandler: (data) => dispatch(actionCreators.requestForLogin(data) )
    }
}

export default connect( mapStateToProps, mapDispatchToProps )(withRouter(LoginComponent))