import React, {Component} from 'react';
import { Link, NavLink, withRouter} from 'react-router-dom';
import urlCongigration from './../config/urlCongigration';

class UserInfoMenu extends Component  {
  adminUrl = urlCongigration()?.adminBaseUrl;
  access_token = '';
  refresh_token = '';
    constructor(props){
        super(props);
        this.access_token = localStorage.getItem('access_token');
        this.refresh_token = localStorage.getItem('refresh_token');
        this.user_type = localStorage.getItem('user_type');
    }

    navigateTOAdmin = ()=> {
      window.location.href = this.adminUrl+ 'navigating/' +this.access_token+'/'+this.refresh_token+'/'+this.user_type;
    }
 

    render (){
        return (
          
          <li className="nav-item no-dot dropdown">
        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <strong >Hi! {this.props.username}</strong> 
        </a>
        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
          <a className="dropdown-item" onClick={this.navigateTOAdmin}>Dashboard</a>
      
          <Link className="dropdown-item text-danger" to="/logout"> Logout</Link>
          
          
        </div>
      </li>



            // <ul className="navbar-nav nav-flex-icons">
            //             <li className="nav-item avatar dropdown no-dot">
            //               <a href="javascript:void(0)" className="nav-link dropdown-toggle" id="navbarDropdownMenuLink-5" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            //                 <strong >Hi! {this.props.username}</strong> </a>
            //               <div className="dropdown-menu dropdown-menu-lg-right dropdown-secondary" aria-labelledby="navbarDropdownMenuLink-5">
            //                 <a onClick={this.navigateTOAdmin}>Dashboard</a>
            //                 <Link className="dropdown-item" to="/logout">Logout</Link>
            //               </div>
            //             </li>
            //           </ul>
        )
    }
}

export default withRouter(UserInfoMenu)